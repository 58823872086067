import React, { useContext, useEffect, useState } from "react";
import HyperLogo from "../../Images/HyperLogo.png";
import styled from "styled-components";
import { Button } from "../../Theme/Hyper";
import { UserContext } from "../../contexts/UserContext";
import Select from "react-select";
import { ControlContext } from "../../contexts/ControlContext";
import { GraphicsContext } from "../../contexts/GraphicsContext";

const Main = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 0.5em;
  background-color: #202731;
  box-sizing: border-box;
  flex-direction: column;
  position: relative;
`;
const Logo = styled.img`
  height: auto;
  width: 50%;
`;
const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 50px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;
const CurrentGraphic = styled.div`
  margin-top: auto;
  width: 100%;
  height: 265px;
  background-color: #181d25;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 0.5em;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 12px;
  margin: 0px;
  padding-bottom: 10px;
`;
const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;
const NavButton = styled(Button)`
  margin: 0.5em;
  background-color: ${(props) => (props.selected ? "#0adb93" : "")};
  color: ${(props) => (props.selected ? "#1d1d1d" : "")};
`;

const Profile = styled.div`
  width: 100%;
  height: 35px;
  background-color: #202731;
  z-index: 1000;
  position: -webkit-sticky;
  position: sticky;
  box-shadow: 0px 4px 12px -20px rgb(0 0 0 / 20%),
    0px 8px 20px 2px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  display: flex;
  align-items: center;
  border-bottom: 1px solid #030304;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  padding-bottom: 5px;
  margin-bottom: 10px;
`;

const SelectContainer = styled(Select)`
  min-width: 200px;
  margin-bottom: 0.5em;
  width: 90%;
`;

const DropdownStyles = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
  }),
  option: (provided, state) => {
    return {
      ...provided,
      backgroundColor: "#0e1219",
      "&:hover": {
        color: "#db0a41",
      },
    };
  },
  control: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
    borderColor: "#384454",
  }),
  valueContainer: (provided) => ({
    ...provided,
    backgroundColor: "#0e1219",
    borderColor: "#384454",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, color: "#fff" };
  },
};

export default function RightSideBar({ race }) {
  const { user, signOut } = useContext(UserContext);
  const { engines, selectedEngine, setSelectedEngine } =
    useContext(ControlContext);
  const {
    clearPVW,
    animateGraphic,
    animateOffGraphic,
    previewGraphic,
    graphicInPreview,
  } = useContext(GraphicsContext);
  const [view, setView] = useState(0);

  return (
    <Main>
      {!window.hideHeader && (
        <Profile>
          {user?.username}
          <Button
            style={{ border: "1px solid #fff", marginLeft: "auto" }}
            onClick={() => {
              signOut();
            }}
          >
            Sign Out
          </Button>
        </Profile>
      )}
      {engines?.length > 1 && (
        <SelectContainer
          styles={DropdownStyles}
          setSize={true}
          value={{
            value: selectedEngine,
            label: engines?.find((e) => e.key === selectedEngine)?.name,
          }}
          options={engines.map((item, index) => {
            return {
              value: item.key,
              label: item.name,
            };
          })}
          onChange={(e) => {
            setSelectedEngine(e.value);
          }}
          placeholder="Round"
        />
      )}
      <Logo src={HyperLogo}></Logo>

      <Buttons>
        <NavButton selected={view === 0} onClick={() => setView(0)}>
          MAIN
        </NavButton>
      </Buttons>
      <GraphicButton
        onClick={async () => {
          previewGraphic("FFStarters", { race });
        }}
      >
        FF Starters
      </GraphicButton>
      <GraphicButton
        onClick={async () => {
          previewGraphic("HFSidePanel", { race });
        }}
      >
        HF SidePanel
      </GraphicButton>
      {graphicInPreview && (
        <CurrentGraphic
          style={{ bottom: window.hideHeader === true ? "40px" : "10px" }}
        >
          {graphicInPreview.name}
          <GraphicButton
            style={{ height: "40px", backgroundColor: "#383f4a" }}
            onClick={() => {
              clearPVW(graphicInPreview.name);
            }}
          >
            CLEAR PVW (CTRL + Q)
          </GraphicButton>

          <GraphicButton
            green
            style={{ height: "40px" }}
            onClick={() => {
              animateGraphic(graphicInPreview.name);
            }}
          >
            IN (F8) or (-)
          </GraphicButton>
          <GraphicButton
            red
            style={{ height: "40px" }}
            onClick={() => {
              animateOffGraphic(graphicInPreview.name);
            }}
          >
            OUT (F9) or (*)
          </GraphicButton>
        </CurrentGraphic>
      )}
    </Main>
  );
}
