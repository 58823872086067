import React, { useContext, useEffect, useState } from "react";
import { APIContext } from "../../contexts/APIContext";
import {
  Switch,
  useHistory,
  useLocation,
  useParams,
  Route,
} from "react-router-dom";
import styled from "styled-components";
import { ScrollBar } from "../../Theme/Hyper";
import Race from "../Race/Race";

const Main = styled.div`
  display: flex;
`;
const RacesDiv = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 1em;
  box-sizing: border-box;
  overflow-y: auto;
  height: 95vh;
`;
const RaceRow = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5em;
  justify-content: center;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.sub ? " #181d25" : props.selected ? "#db0a41" : "#202731"};
  border-bottom: 2px solid #181d25;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
  :hover {
    ${(props) => !props.noHover && !props.selected && { color: "#d11e42" }}
  }
`;
const Content = styled.div`
  display: flex;
  box-sizing: border-box;
  flex: 1;
  width: 100vw;
`;

function Meeting() {
  const history = useHistory();
  const { meetingID } = useParams();
  const { getRacesByMeeting, getMeetingById } = useContext(APIContext);
  const [races, setRaces] = useState([]);
  const [meeting, setMeeting] = useState({});
  useEffect(() => {
    getRacesByMeeting({ meeting_id: meetingID }).then((json) => {
      setRaces(json);
    });
  }, [getRacesByMeeting, meetingID]);

  useEffect(() => {
    getMeetingById({ id: meetingID }).then((json) => {
      setMeeting(json);
    });
  }, [getMeetingById, meetingID]);
  return (
    <Main>
      <RacesDiv>
        {races?.map((race) => {
          return (
            <RaceRow
              onClick={() => {
                history.push("/" + meeting?._id + "/" + race?._id);
              }}
            >
              {race?.raceName} - {race?.time}
            </RaceRow>
          );
        })}
      </RacesDiv>
      <Content>
        <Switch>
          <Route path="/:meetingID/:raceID">
            <Race />
          </Route>
        </Switch>
      </Content>
    </Main>
  );
}

export default Meeting;
