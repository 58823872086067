import React, { useContext, useEffect } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Hyper from "./Theme/Hyper";
import Main from "./components/Views/Main";
import { ControlContext, ControlProvider } from "./contexts/ControlContext";
import { GraphicsContext, GraphicsProvider } from "./contexts/GraphicsContext";
import { UIContext, UIProvider } from "./contexts/UIContext";
import Config from "./components/Views/Config";
import { APIProvider } from "./contexts/APIContext";

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function App() {
  window.command_server =
    getParameterByName("command_server") ||
    window.ENV?.REACT_APP_COMMAND_SERVER ||
    process.env.REACT_APP_COMMAND_SERVER;
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ThemeProvider theme={Hyper}>
        <ControlProvider>
          <GraphicsProvider>
            <APIProvider>
              <UIProvider>
                <ShowMain>
                  <Switch>
                    <Route path="/Config">
                      <Config />
                    </Route>
                    <Route path="/">
                      <Main />
                    </Route>
                  </Switch>
                </ShowMain>
              </UIProvider>
            </APIProvider>
          </GraphicsProvider>
        </ControlProvider>
      </ThemeProvider>
    </Router>
  );
}
function ShowMain({ children }) {
  const { uiView, setUIView } = useContext(UIContext);
  const { clearPVW, graphicInPreview } = useContext(GraphicsContext);
  const { clear } = useContext(ControlContext);
  useEffect(() => {
    function handleKeyDown(e) {
      console.log(e.keyCode);

      if (e.keyCode === 112) {
        setUIView(0);
      }
      if (e.keyCode === 113) {
        setUIView(1);
      }
      if (e.keyCode === 114) {
        setUIView(2);
      }
      if (e.keyCode === 81 && e.ctrlKey && !e.shiftKey) {
        if (graphicInPreview && graphicInPreview.name) {
          clearPVW(graphicInPreview.name);
        }
      }

      if (e.keyCode === 81 && e.ctrlKey && e.shiftKey) {
        if (graphicInPreview && graphicInPreview.name) {
          clearPVW(graphicInPreview.name);
        }
        clear();
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [graphicInPreview]);
  return (
    <div
      style={{ display: uiView === 1 || !window.process ? "block" : "none" }}
    >
      {children}
    </div>
  );
}
export default App;
