export class L3Live {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "L3Live";
    this.scene = "L3_Live";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];

    this.data.push({
      name: "Name_1",
      value: { text: options.rider?.shortName ?? options.rider?.name },
    });

    this.data.push({
      name: "TranerName",
      value: { text: options.rider?.trainer },
    });

    this.data.push({
      name: "Odds",
      value: { text: options.rider?.prices?.at(-1)?.fraction ?? "" },
    });
    let number_text = "";
    if (options.rider?.runnerNum === 1) {
      number_text = "One";
    }
    if (options.rider?.runnerNum === 2) {
      number_text = "Two";
    }
    if (options.rider?.runnerNum === 3) {
      number_text = "Three";
    }
    if (options.rider?.runnerNum === 4) {
      number_text = "Four";
    }
    if (options.rider?.runnerNum === 5) {
      number_text = "Five";
    }
    if (options.rider?.runnerNum === 6) {
      number_text = "Six";
    }
    this.data.push({
      name: "Number_1",
      value: { image: number_text + ".png" },
    });
    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
