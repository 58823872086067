import { FStarters } from "./FFStarters";
import { HFSidePanel } from "./HFSidePanel";
import { L3Live } from "./L3Live";

export default function graphics({
  preview,
  animate,
  update,
  animateOff,
  triggerAnimation,
  clearPreview,
}) {
  return [
    new FStarters({ preview, animate, update, animateOff, triggerAnimation }),
    new HFSidePanel({ preview, animate, update, animateOff, triggerAnimation }),
    new L3Live({ preview, animate, update, animateOff, triggerAnimation }),
  ];
}
