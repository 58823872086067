import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { APIContext } from "../../contexts/APIContext";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import styled from "styled-components";

const Main = styled.div`
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-right: 1em;
  margin-left: 1em;
  box-sizing: border-box;
  min-height: 80vh;
  max-height: 80vh;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  width: 100%;
  overflow-y: auto;
  flex-direction: column;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgb(32, 39, 49);
  }
  th:nth-child(1) {
    width: 65px;
  }
  th:nth-child(3) {
    width: 100px;
  }
  th {
    padding: 10px;
    border-top: 1px solid #0e1219;
    color: white;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #0e1219;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
    > td > div {
      color: #ea1047;
    }
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

export default function Race({ onRaceSelected }) {
  const { raceID } = useParams();
  const { getRaceById } = useContext(APIContext);
  const { previewGraphic } = useContext(GraphicsContext);

  const [race, setRace] = useState();
  useEffect(() => {
    getRaceById({ id: raceID }).then((data) => {
      setRace(data);
      onRaceSelected(data);
    });
  }, [getRaceById, raceID]);
  return (
    <Main>
      <div>{race?.raceName}</div>
      <div>
        <Table>
          <thead>
            <tr>
              <TH>#</TH>
              <TH>Name</TH>
            </tr>
          </thead>
          <tbody>
            {race?.selections?.map((row, index) => {
              return (
                <>
                  {/* {(index === 0 ||
                    meetings[index - 1]?.meeting?._id?.toString() !==
                      meeting?._id?.toString()) && <div>{meeting?.venue}</div>} */}
                  <tr
                    onClick={() => {
                      previewGraphic("L3Live", { rider: row });
                    }}
                  >
                    <td>{row?.runnerNum}</td>
                    <td>{row?.shortName ?? row?.name}</td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>
      </div>
    </Main>
  );
}
