export class FStarters {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "FFStarters";
    this.scene = "FF_Starters_1-6";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.data = [];
    debugger;
    this.data.push({
      name: "Header_Text",
      value: { text: options.race?.meeting?.venue },
    });
    this.data.push({
      name: "SubHeader_Text",
      value: { text: options.race?.raceName },
    });
    this.data.push({
      name: "Time",
      value: { text: options.race?.time },
    });
    this.data.push({
      name: "CourseLength",
      value: { text: options.race?.distance },
    });
    for (let i = 0; i < options?.race?.selections?.length; i++) {
      let row = options?.race?.selections[i];
      this.data.push({
        name: "Name_" + (i + 1),
        value: { text: row?.shortName ?? row?.name },
      });
      this.data.push({
        name: "Number_" + (i + 1),
        value: { text: row?.runnerNum },
      });
      this.data.push({
        name: "L" + (i + 1) + "_Col_1",
        value: { text: row?.prices?.at(-4)?.fraction ?? "" },
      });
      this.data.push({
        name: "L" + (i + 1) + "_Col_2",
        value: { text: row?.prices?.at(-3)?.fraction ?? "" },
      });
      this.data.push({
        name: "L" + (i + 1) + "_Col_3",
        value: { text: row?.prices?.at(-2)?.fraction ?? "" },
      });
      this.data.push({
        name: "L" + (i + 1) + "_Col_4",
        value: { text: row?.prices?.at(-1)?.fraction ?? "" },
      });
    }
    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.onAir = true;

    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
