import { getApolloContext } from "@apollo/client";
import gql from "graphql-tag";
import React, { useState, useEffect, useContext } from "react";

const MEETING_BY_ID = gql`
  query Meeting($id: String) {
    meetingByID(id: $id) {
      _id
      sis_ID
      venue
    }
  }
`;

const RACES = gql`
  query Races {
    races {
      _id
      sis_ID
      raceName
      time
    }
  }
`;

const RACE_BY_ID = gql`
  query Race($id: String) {
    raceByID(id: $id) {
      _id
      sis_ID
      raceName
      time
      selections
      distance
      meeting {
        venue
      }
    }
  }
`;

const RACES_BY_MEETING = gql`
  query RacesByMeeting($meeting_id: String) {
    racesByMeeting(meeting_id: $meeting_id) {
      _id
      sis_ID
      raceName
      time
    }
  }
`;

const APIContext = React.createContext([{}, () => {}]);

const APIProvider = (props) => {
  const { client } = useContext(getApolloContext());

  function getRaces() {
    return client
      .query({
        query: RACES,
      })
      .then((response) => {
        return response.data.races;
      })
      .catch((err) => console.error(err));
  }

  function getRacesByMeeting({ meeting_id }) {
    return client
      .query({
        query: RACES_BY_MEETING,
        variables: {
          meeting_id,
        },
      })
      .then((response) => {
        return response.data.racesByMeeting;
      })
      .catch((err) => console.error(err));
  }

  function getMeetingById({ id }) {
    return client
      .query({
        query: MEETING_BY_ID,
        variables: {
          id,
        },
      })
      .then((response) => {
        return response.data.meetingByID;
      })
      .catch((err) => console.error(err));
  }
  function getRaceById({ id }) {
    return client
      .query({
        query: RACE_BY_ID,
        variables: {
          id,
        },
      })
      .then((response) => {
        return response.data.raceByID;
      })
      .catch((err) => console.error(err));
  }

  return (
    <APIContext.Provider
      value={{
        getRaces,
        getRacesByMeeting,
        getMeetingById,
        getRaceById,
      }}
    >
      {props.children}
    </APIContext.Provider>
  );
};
export { APIContext, APIProvider };
