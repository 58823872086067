/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Date from "./Date";
import { ScrollBar } from "../../Theme/Hyper";
import { UIContext, MODE_CONSTS } from "../../contexts/UIContext";
import CalendarUI from "../UI/Calendar/CalendarUI";
import "../../calendar.css";
import gql from "graphql-tag";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  Route,
  Switch,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Race from "../Race/Race";
import RightSideBar from "../RightSideBar/RightSideBar";
const MEETINGS_BY_DATE = gql`
  query Meetings($date: String) {
    meetingsByDate(date: $date) {
      _id
      sis_ID
      venue
      date
      start_time
    }
  }
`;

const RACES_BY_DATE = gql`
  query Races($date: String) {
    racesByDate(date: $date) {
      _id
      sis_ID
      raceName
      time
      categoryCode
      country
      status
      meeting {
        venue
      }
    }
  }
`;
const MEETINGS_BY_MONTH = gql`
  query Meetings($date: String) {
    meetingsByMonth(date: $date) {
      _id
      date
    }
  }
`;

const GamesDiv = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  overflow-y: auto;
  height: 95vh;
`;
const Game = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5em;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.sub ? " #181d25" : props.selected ? "#db0a41" : "#202731"};
  border-bottom: 2px solid #181d25;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
  :hover {
    ${(props) => !props.noHover && !props.selected && { color: "#d11e42" }}
  }
`;

const Competitions = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #202731;
  margin-left: 1px;
  min-width: 500px;
  max-width: 500px;
  padding: 1em;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  th:nth-child(1) {
    width: 65px;
  }
  th:nth-child(3) {
    width: 100px;
  }
  th {
    padding: 10px;
    border-top: 1px solid #0e1219;
    color: white;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #0e1219;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
    > td > div {
      color: #ea1047;
    }
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

const Content = styled.div`
  display: flex;
  box-sizing: border-box;
  flex: 1;
  width: 100vw;
`;
const RightBar = styled.div`
  display: flex;
  width: 300px;
  min-width: 300px;
  box-shadow: -3px 1px 3px 0 rgb(0 0 0 / 42%);
`;
const RaceContent = styled.div`
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-right: 1em;
  margin-left: 1em;
  box-sizing: border-box;
  min-height: 80vh;
  max-height: 80vh;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #181d25;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  flex-direction: column;
`;

export default function Races({}) {
  const location = useLocation();
  const history = useHistory();
  const { url_date } = useParams();
  const [races, setRaces] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(moment());
  const [monthMeetings, setMonthMeetings] = useState();
  const [selectedRace, setSelectedRace] = useState({});
  const [getMonthMeetings, { data: month_data }] = useLazyQuery(
    MEETINGS_BY_MONTH,
    {
      fetchPolicy: "network-only",
    }
  );
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

  // TODO - ADDD LOADING AND ERROR STATES
  const [getRaces, { data }] = useLazyQuery(RACES_BY_DATE);

  useEffect(() => {
    if (date) {
      getRaces({ variables: { date: date } });
    }
  }, [date, getRaces]);

  useEffect(() => {
    if (data) {
      setRaces([...data.racesByDate]);
    }
  }, [data]);

  useEffect(() => {
    if (selectedMonth) {
      getMonthData();
    }
  }, [selectedMonth, getMonthMeetings]);

  useEffect(() => {
    setDate(url_date);
  }, [url_date]);

  useEffect(() => {
    if (month_data) {
      setMonthMeetings([...(month_data?.meetingsByMonth || [])]);
    }
  }, [month_data]);

  function getMonthData() {
    getMonthMeetings({
      variables: { date: selectedMonth.format("YYYY-MM-DD") },
    });
  }

  return (
    <Content>
      <Competitions>
        <CalendarUI
          onChangeDate={(date) => {
            setDate(moment(date).format("YYYY-MM-DD"));
            history.push("/" + moment(date).format("YYYY-MM-DD"));
          }}
          date={moment()}
          onActiveStartDateChange={({ activeStartDate, value }) => {
            setSelectedMonth(moment(activeStartDate));
          }}
          highlights={monthMeetings?.map((m) => m.date)}
        />
        <GamesDiv>
          <Table>
            <thead>
              <tr>
                <TH>Type</TH>
                <TH>Nat</TH>
                <TH>Race</TH>
                <TH>Time</TH>
                <TH>Course</TH>
                <TH>Status</TH>
              </tr>
            </thead>
            <tbody>
              {races
                ?.sort((a, b) => {
                  return (
                    moment(a?.time, "HH:mm:ss")?.valueOf() -
                    moment(b?.time, "HH:mm:ss")?.valueOf()
                  );
                })
                ?.map((race, index) => {
                  return (
                    <>
                      {/* {(index === 0 ||
                    meetings[index - 1]?.meeting?._id?.toString() !==
                      meeting?._id?.toString()) && <div>{meeting?.venue}</div>} */}
                      <tr
                        selected={selectedRace?._id === race?._id}
                        onClick={() => {
                          history.push(
                            "/" +
                              moment(date).format("YYYY-MM-DD") +
                              "/" +
                              race?._id
                          );
                        }}
                      >
                        <td>{race.categoryCode}</td>
                        <td>{race.country}</td>
                        <td>{race.raceName}</td>
                        <td>{moment(race?.time, "HH:mm")?.format("HH:mm")}</td>
                        <td>{race?.meeting?.venue}</td>
                        <td>{race?.status}</td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </Table>
        </GamesDiv>
      </Competitions>
      <RaceContent>
        <Switch>
          <Route path="/:url_date/:raceID">
            <Race
              onRaceSelected={(race) => {
                setSelectedRace(race);
              }}
            />
          </Route>
        </Switch>
      </RaceContent>
      <RightBar>
        <RightSideBar race={selectedRace} />
      </RightBar>
    </Content>
  );
}
